<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('service.center')" class="item_inbox service_questions">
    <CCol cols="3" lg="3" md="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <span>{{$t('service.Questions')}} ({{serviceQuestions.length}})</span>
        </CCardHeader>
        <CCardBody class="items pb-0">
          <CRow class="h-100">
            <CCol cols="12" lg="12" class="pt-0 pb-0">              
              <div class="mb-2">
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <CButtonGroup class="w-100">
                      <CButton color="outline-secondary"
                               v-for="(value, name, key) in [$t('service.All_open_questions'), $t('service.My_questions'), $t('service.All_questions')]"
                               :key="key"
                               :pressed="value === serviceQuestionsToShow ? true : false"
                               @click="serviceQuestionsToShow = value; getServiceQuestions();">
                        {{value}}
                      </CButton>
                    </CButtonGroup>
                  </CCol>
                </CRow>
              </div>
              <div class="mb-2">
                <multiselect class="data_table open_absolute"
                             v-model="serviceQuestionsFilter.employees" 
                             :options="serviceQuestionsEmployees" 
                             :multiple="true"                
                             :placeholder="$t('service.Select_enquirers')" 
                             :selectLabel="$t('service.Add_enquirer')" 
                             :selectedLabel="$t('Selected')"
                             :deselectLabel="$t('service.Remove_enquirer')"
                             track-by="user_id_external" 
                             label="label"
                             @search-change="asyncFindEmployee"
                             @input="updateEmployeeFilter();">
                  <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                </multiselect>                
              </div>
              <div class="mb-2">
                <multiselect class="data_table open_absolute"
                             v-model="serviceQuestionsFilter.folders" 
                             :options="serviceQuestionFolders" 
                             :multiple="true"
                             :close-on-select="false"             
                             :placeholder="$t('service.Select_folders')"
                             :selectLabel="$t('service.Add_folder')" 
                             :selectedLabel="$t('Added')"
                             :deselectLabel="$t('service.Remove_folder')"
                             track-by="service_question_folder_id_external" 
                             label="folder_name"
                             @input="updateFolderFilter()">
                  <span slot="noResult">{{ $t('common.no_form_fields_found') }}</span>
                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                </multiselect>
              </div>
              <div class="mb-2_5">
                <CInput class="mb-0 searchbar" type="text" v-model="contentSearchTerm" :placeholder="$t('Search_within') + ' ' + $t('common.questions')"/>
              </div>
              <CRow v-if="serviceQuestions.length > 0" class="item_container add_top_border">
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <CRow class="item"                        
                        v-for="question in searchedServiceQuestions"
                        v-bind:key="question.service_question_id_external"
                        v-bind:class="{active: question.service_question_id_external === activeServiceQuestion.service_question_id_external, 'updated' : question.update_available === true}"
                        @click="setActiveServiceQuestion(question.service_question_id_external)">
                                        
                    <CCol cols="12" lg="12">
                      <div class="d-flex align-items-center">
                        <div class="participant single mr-2">                    
                          <div class="d-flex align-items-center justify-content-center">                            
                            <div v-if="question.participant.profile_image" class="profile_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + question.participant.user_id_external + '/' + question.participant.profile_image + '/' + clientToken + ')' }"></div>
                            <div v-else-if="question.participant.name" class="profile_icon">
                              <userProfileAvatar :username="question.participant.name" :size="30"/>
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1">
                          <div class="d-flex align-items-center" v-bind:class="{'mb-1' : question.last_message || (question.form_submission_id_external && !question.last_message)}">
                            <div class="flex-grow-1 subject">
                              <span v-if="question.question" v-line-clamp="1">{{question.question}}</span>
                              <span v-else-if="question.form_submission_id_external && question.form_submission" v-line-clamp="1">{{$t('forms.Form_submitted')}} - {{question.form_submission.form_name}}</span>
                            </div>
                            <div class="meta ml-4">
                              <span v-if="question.last_message" v-line-clamp="1">{{question.last_message.created | moment("DD-MM-YYYY HH:mm")}}</span>
                              <span v-else v-line-clamp="1">{{question.created | moment("DD-MM-YYYY HH:mm")}}</span>
                            </div>
                          </div>
                          <div v-if="question.last_message" class="d-flex align-items-center last_message">
                            <div class="flex-grow-1">
                              <div v-if="question.last_message.content">
                                <span v-line-clamp="1">{{question.last_message.user_message ? $t('You') : question.last_message.name }}: {{question.last_message.content}}</span>
                              </div>
                              <div v-else-if="question.last_message.attachments">
                                <div v-if="question.last_message.attachments.files">
                                  <span>{{question.last_message.user_message ? $t('You') : question.last_message.name }}: </span>
                                  <span><i class="fa-solid fa-file-lines mr-1"></i>{{question.last_message.attachments.files.length > 1 ? $t('common.Files') : $t('common.File')}}</span>
                                </div>
                                <div v-if="question.last_message.attachments.images">
                                  <span>{{question.last_message.user_message ? $t('You') : question.last_message.name }}: </span>
                                  <span><i class="fa-solid fa-file-image mr-1"></i>{{question.last_message.attachments.images.length > 1 ? $t('common.Images') : $t('common.Image')}}</span>                                  
                                </div>
                              </div>
                            </div>
                            <div v-if="question.update_available === true" class="ml-2 new_messages">
                              <span>{{question.total_new_thread_messages}}</span>
                            </div>
                          </div>
                          <div v-if="question.form_submission_id_external && !question.last_message" class="d-flex align-items-center last_message">
                            <span v-line-clamp="1">{{question.employee_name}} {{$t('forms.employee_submitted_a_form')}}</span>
                          </div>
                          <div class="mt-1">
                            <b-tag class="item_status" v-bind:style="{'background-color' : getServiceQuestionStatusColor(question.service_question_status_tag)}">{{question.service_question_status}}</b-tag>
                          </div>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              <div v-else class="mb-2">
                <span>{{$t('kb.No_questions_found_with_these_filters')}}.</span>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol cols="6" lg="6" md="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <div class="w-100 d-flex align-items-center">
            <span v-if="activeServiceQuestion.service_question_id_external && activeServiceQuestion.question">{{activeServiceQuestion.question}}</span>
            <span v-else-if="activeServiceQuestion.service_question_id_external && activeServiceQuestion.form_submission_id_external && activeServiceQuestion.form_submission" v-line-clamp="1">{{$t('forms.Form_submitted')}} - {{activeServiceQuestion.form_submission.form_name}}</span>
            <span v-else>{{$t('common.Question')}}</span>
          </div>
        </CCardHeader>
        <CCardBody class="item_messages" v-bind:class="{'p-0' : activeServiceQuestion.service_question_id_external}">
          <CRow v-if="activeServiceQuestion.service_question_id_external !== null" class="h-100 m-0">
            <CCol cols="12" lg="12" class="p-0">
              <div id="itemMessageContainer" ref="itemMessageContainer" class="item_message_container" v-bind:class="{'hidden' : activeServiceQuestionMessagesHidden}" @scroll="updateServiceQuestionMessages()">
                <loadingSpinner v-if="activeServiceQuestionMessagesLoading" mode="auto" size="small" :content="null" class="mb-2_5"/>
                <!-- Form Submission -->
                <div class="w-100 mb-2_5 form_submission" v-if="activeServiceQuestion.form_submission_id_external">
                  <div class="d-flex item_message" v-bind:class="{ left : !activeServiceQuestion.form_submission_by_user, right : activeServiceQuestion.form_submission_by_user }">
                    <div class="icon" v-bind:class="{ 'mr-2' : !activeServiceQuestion.form_submission_by_user, 'ml-2' : activeServiceQuestion.form_submission_by_user }">
                      <div v-if="activeServiceQuestion.employee_profile_image"
                           class="profile_image"
                           v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + activeServiceQuestion.employee_user_id_external + '/' + activeServiceQuestion.employee_profile_image + '/' + clientToken + ')' }">
                      </div>
                      <div v-else-if="activeServiceQuestion.employee_name" class="profile_icon">
                        <userProfileAvatar :username="activeServiceQuestion.employee_name" :size="25"/>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <div class="content">
                        <div class="name">
                          <span><b>{{activeServiceQuestion.employee_name}}</b> {{$t('forms.employee_submitted_a_form')}}</span>
                        </div>
                        <div class="form">
                          <hr class="mt-2_5 mb-2_5">
                          <span class="d-block form_name">{{activeServiceQuestion.form_submission.form_name}}</span>
                          <div class="form_fields">
                            <div v-for="(value, index) in activeServiceQuestion.form_submission.values" v-bind:key="index" class="form_field">
                              <div><b>{{value.field_name}}</b></div>
                              <div>{{value.field_value}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="meta">
                          <span>{{activeServiceQuestion.form_submission.submitted | moment("DD-MM-YYYY HH:mm")}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Chat Messages -->
                <div class="w-100" v-if="activeServiceQuestionMessages.length > 0">
                  <div v-for="(message, index) in activeServiceQuestionMessages"
                       v-bind:key="message.thread_message_id_external"
                       class="d-flex item_message"
                       v-bind:class="{ left : !message.user_message, right : message.user_message, grouped : checkGroupedMessage(index) }"
                       v-observe-visibility=" {callback: (isVisible, entry) => visibilityChanged(isVisible, entry, message.thread_message_id_external), throttle: 2000, once: true }">

                    <div class="icon" v-bind:class="{ 'ml-2' : message.user_message, 'mr-2' : !message.user_message}">
                      <div v-if="message.user_message" 
                           class="profile_image"
                           v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + userData.user_id_external + '/' + userData.profile_image + '/' + clientToken + ')' }">
                      </div>
                      <div v-else-if="!message.user_message && message.profile_image"
                           class="profile_image"
                           v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + message.user_id_external + '/' + message.profile_image + '/' + clientToken + ')' }">
                      </div>
                      <div v-else-if="message.name" class="profile_icon">
                        <userProfileAvatar :username="message.name" :size="25"/>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <div class="content">
                        <div class="name">
                          <span><b>{{message.name}}</b></span>
                        </div>                       
                        <div v-if="message.attachments.total_attachments > 0" class="attachments">                            
                          <div v-if="message.attachments.images && message.attachments.images.length > 0"
                                class="image_grid"
                                v-bind:class="{'grid_1' : message.attachments.images.length == 1, 
                                               'grid_2' : message.attachments.images.length == 2, 
                                               'grid_3' : message.attachments.images.length >= 3}">
                                                      
                            <div v-for="(image, index) in message.attachments.images.slice(0, maxGridImages)" v-bind:key="index" v-bind:style="{ backgroundImage: 'url(' + image.url + ')' }" @click="openImageLightbox(message.attachments.images, index)" class="image_grid_item">
                              <div v-if="index == maxGridImages - 1 && message.attachments.images.length > 3" class="grid_more h-100 w-100">
                                <span>{{message.attachments.images.length - maxGridImages}}+</span>
                              </div>
                            </div>
                          </div>

                          <CoolLightBox v-if="message.attachments.images && message.attachments.images.length > 0" 
                                        :items="lightboxImages"
                                        :index="lightboxActiveIndex"
                                        :slideshow="false"
                                        :enableScrollLock="true"
                                        @close="lightboxActiveIndex = null">
                          </CoolLightBox>

                          <div v-if="message.attachments.files && message.attachments.files.length > 0">
                            <div v-for="(file, index) in message.attachments.files" v-bind:key="index" class="file">
                              <div class="w-100 d-flex align-items-center pointer">
                                <div class="flex-grow-1">
                                  <div class="d-flex align-items-center" @click="file.file_extension === 'pdf' ? openFileAttachment(file.url) : null">
                                    <div class="icon mr-1">
                                      <i v-if="file.file_extension === 'pdf'" class="fas fa-file-pdf"></i>                                
                                      <i v-if="file.file_extension === 'ppt' || file.file_extension === 'pptx' || file.file_extension === 'odp'" class="fas fa-file-powerpoint"></i>
                                      <i v-if="file.file_extension === 'doc' || file.file_extension === 'docx' || file.file_extension === 'odt'" class="fas fa-file-word"></i>
                                      <i v-if="file.file_extension === 'xls' || file.file_extension === 'xlsx' || file.file_extension === 'ods'" class="fas fa-file-excel"></i>
                                    </div>
                                    <div class="flex-grow-1">
                                      <span>{{file.attachment_name}}</span>
                                    </div>
                                  </div>
                                </div>
                                <div @click="downloadFileAttachment(file.attachment_name, file.url)" class="d-flex align-items-center">
                                  <span class="material-icons">file_download</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="message.content" class="text" v-bind:class="{'mt-0' : checkGroupedMessage(index) && message.attachments.total_attachments === 0}">
                          <span>{{message.content}}</span>
                        </div>
                        <div class="meta">
                          <span>{{message.created | moment("DD-MM-YYYY HH:mm")}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="m-0">
              <div v-if="addNewServiceQuestionReplyAttachment" class="add_attachment">
                <CRow>
                  <CCol cols="12" lg="12" class="pb-0">
                    <div class="pointer" @click="resetServiceQuestionAttachment()">
                      <i class="fas fa-times"></i>
                    </div>
                    <div v-if="showServiceQuestionAttachmentTypes" class="attachment_types">
                      <CRow>
                        <CCol class="pb-0" v-for="type in serviceQuestionAttachmentTypes" v-bind:key="type.attachment_type_tag">                                          
                          <div class="d-flex flex-column justify-content-center align-items-center pointer attachment_type" @click="setServiceQuestionAttachmentType(type);">
                            <div class="attachment_type_icon">                              
                              <i v-if="type.attachment_type_tag === 'image'" class="fas fa-image"></i>
                              <i v-if="type.attachment_type_tag === 'file'" class="fas fa-file-alt"></i>                              
                            </div>
                            <span>{{type.attachment_type}}</span>
                          </div>                      
                        </CCol>
                      </CRow>
                    </div>

                    <div v-if="selectedAttachmentTypeTag == 'image'">
                      <input id="imageUpload"
                            type="file"
                            multiple
                            accept="image/png, image/gif, image/jpeg"
                            :value="newServiceQuestionReply.uploaded_images"
                            @change="onImagesChange" 
                            hidden>

                      <div v-if="newServiceQuestionReply.attachments.length > 0" id="preview" class="mt-1 attachment_images_preview">
                        <div v-for="(image, index) in newServiceQuestionReply.attachments" :key="index" class="attachment_image">
                          <img :src="image.image_url"/>                                          
                        </div>                          
                      </div>
                    </div>

                    <div v-if="selectedAttachmentTypeTag == 'file'">
                      <input id="fileUpload"
                            type="file" 
                            multiple                            
                            accept="application/pdf,
                                    application/msword,
                                    application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                    application/vnd.oasis.opendocument.text,
                                    application/vnd.ms-powerpoint,
                                    application/vnd.openxmlformats-officedocument.presentationml.presentation,
                                    application/vnd.oasis.opendocument.presentation,
                                    application/vnd.ms-excel,
                                    application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                    application/vnd.oasis.opendocument.spreadsheet"
                            :value="newServiceQuestionReply.uploaded_files"
                            @change="onFilesChange" 
                            hidden>

                      <div v-if="newServiceQuestionReply.attachments.length > 0" id="preview" class="mt-1 attachment_documents">
                        <div v-for="(document, index) in newServiceQuestionReply.attachments" :key="index" class="attachment_document">                        
                          <span><i class="fas fa-file mr-1"/>{{document.file_name}}</span>
                        </div>                          
                      </div>      
                    </div>                    
                    
                  </CCol>
                </CRow>
              </div>
              <div class="your_response">
                <CRow>
                  <CCol cols="12" lg="12" v-bind:class="{'pt-0' : addNewServiceQuestionReplyAttachment}">
                    <div class="w-100 d-flex">
                      <div class="flex-grow-1">
                        <CTextarea @input="countdown()" maxlength="250" rows="3" class="m-0 flex-grow-1" type="text" v-model="newServiceQuestionReply.reply" :placeholder="$t('service.Your_reply')"/>
                        <p class="countdown mt-1 mb-0" v-if="remainingCount > 0">{{remainingCount}} {{$t('common.characters_remaining')}}</p>
                      </div>
                      <CButton color="primary" 
                               class="ml-2 mr-0 p-0 d-flex align-items-center justify-content-center"
                               @click="addNewServiceQuestionReplyAttachment = true; showServiceQuestionAttachmentTypes = true;"
                               :disabled="addNewServiceQuestionReplyAttachment || chatReplyButtonDisabled">
                        <span class="material-icons text-center">attachment</span>
                      </CButton>
                      <CButton color="primary"
                               class="ml-2 mr-0 p-0 d-flex align-items-center justify-content-center" 
                               @click="submitServiceQuestionReply(activeServiceQuestion.service_question_id_external, false);"
                               :disabled="(!newServiceQuestionReply.reply && newServiceQuestionReply.attachments.length === 0) || chatReplyButtonDisabled">
                        <span class="material-icons text-center">send</span>
                      </CButton>
                      <CButton color="primary" 
                               class="ml-2 mr-0 p-0 d-flex align-items-center justify-content-center"
                               @click="submitServiceQuestionReply(activeServiceQuestion.service_question_id_external, true);"
                               :disabled="(!newServiceQuestionReply.reply && newServiceQuestionReply.attachments.length === 0) || chatReplyButtonDisabled">
                        <span class="material-icons text-center">check</span>
                      </CButton>                      
                    </div>
                  </CCol>
                </CRow>
              </div>
            </CCol>
          </CRow>
          <CRow v-else-if="activeServiceQuestion.service_question_id_external === null">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span>{{$t('service.Select_to_view_details')}}</span>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol cols="3" lg="3" md="12">
      <CCard class="h-100 mb-0">
        <CCardHeader class="pb-0">
          <div class="w-100 d-flex align-items-center">
            <div v-if="activeServiceQuestion.service_question_id_external === null"> 
              <span>{{$t('common.Details')}}</span>
            </div>
            <div v-else class="w-100 d-flex">
              <span class="flex-grow-1">{{activeServiceQuestion.service_question_number}} </span>
              <b-tag class="item_status" v-bind:style="{'background-color' : getServiceQuestionStatusColor(activeServiceQuestion.service_question_status_tag)}">{{activeServiceQuestion.service_question_status}}</b-tag>
            </div>
          </div>        
        </CCardHeader>
        <CCardBody class="item_details">
          <CRow v-if="activeServiceQuestion.service_question_id_external !== null" class="h-100">
            <CCol cols="12" lg="12" class="pt-0 pb-0">    
              <div class="h-100 d-flex flex-column">
                <div class="d-flex">
                  <div class="mr-2">
                    <div v-if="activeServiceQuestion.employee_profile_image" class="mt-1 profile_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + activeServiceQuestion.employee_user_id_external + '/' + activeServiceQuestion.employee_profile_image + '/' + clientToken + ')' }"></div>
                    <div v-else-if="activeServiceQuestion.employee_name" class="mt-1 profile_icon">
                      <userProfileAvatar :username="activeServiceQuestion.employee_name" :size="30"/>
                    </div>
                  </div>                        
                  <div class="flex-grow-1">                
                    <p class="mt-0 mb-1"><b>{{activeServiceQuestion.employee_name}}</b></p>
                    <p class="mt-0 mb-1">{{activeServiceQuestion.employee_team_name}} ({{activeServiceQuestion.employee_department_name}})</p>
                    <p v-if="activeServiceQuestion.employee_phone_number" class="m-0">{{activeServiceQuestion.employee_phone_number}}</p>
                  </div>
                </div>
                <hr class="mt-2 mb-2">
                <div class="mb-2">
                  <multiselect class="data_table open_absolute"
                               v-model="activeServiceQuestion.folder" 
                               :options="serviceQuestionFolders" 
                               :multiple="false"
                               :hide-selected="true"
                               :close-on-select="true"             
                               :placeholder="$t('service.Move_to_other_folder')" 
                               :selectLabel="$t('service.Move_to_folder')"
                               track-by="service_question_folder_id_external" 
                               label="folder_name"
                               @input="setServiceQuestionFolder(activeServiceQuestion.service_question_id_external, activeServiceQuestion.folder.service_question_folder_id_external);">
                    <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                    <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                  </multiselect>
                </div>                
                <div class="mb-2">
                  <CButton class="w-100 assign_to_me" color="secondary" @click="setServiceQuestionAssignee(activeServiceQuestion.service_question_id_external, userData.user_id_external)">
                    <div class="d-flex align-items-center justify-content-center">
                      <div v-if="userData.profile_image" class="profile_image mr-1" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + userData.user_id_external + '/' + userData.profile_image + '/' + clientToken + ')' }"></div>
                      <div v-else-if="activeServiceQuestion.employee_name" class="profile_icon mr-1">
                        <userProfileAvatar :username="activeServiceQuestion.employee_name" :size="20"/>
                      </div>
                      <span>{{$t('Assign_to_me')}}</span>
                    </div>
                  </CButton>
                </div>
                <div class="mb-2">
                  <multiselect class="data_table open_absolute"
                               v-model="activeServiceQuestion.assignee" 
                               :options="serviceQuestionsAssignees" 
                               :multiple="false"
                               :hide-selected="true"
                               :close-on-select="true"             
                               :placeholder="$t('service.Assign_to_other')" 
                               :selectLabel="$t('service.Set_as_assignee')"
                               track-by="user_id_external" 
                               label="label"
                               @search-change="asyncFindAssignee"
                               @input="setServiceQuestionAssignee(activeServiceQuestion.service_question_id_external, activeServiceQuestion.assignee.user_id_external);">
                    <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                    <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                  </multiselect>
                </div>
                <div class="mb-2">
                  <CButton class="w-100" color="primary" @click="setServiceQuestionStatus(activeServiceQuestion.service_question_id_external, 'done');">
                    <i class="fas fa-phone mr-1"/>{{$t('service.Handled_by_phone')}}
                  </CButton>
                </div>
                <div>
                  <CButton class="w-100" color="primary" @click="setServiceQuestionStatus(activeServiceQuestion.service_question_id_external, 'done');">
                    <i class="fas fa-check mr-1"/>{{$t('service.Mark_as_done')}}
                  </CButton>
                </div>
                <hr class="mt-2 mb-2">
                <div class="notes flex-grow-1">
                  <div class="h-100 d-flex flex-column">
                    <p class="mb-1"><strong>{{$t('common.Notes')}}</strong></p>
                    <div class="flex-grow-1">
                      <div v-if="activeServiceQuestionNotes.length > 0" class="notes_container">
                        <div v-for="note in activeServiceQuestionNotes" v-bind:key="note.service_question_note_id_external" class="note">                          
                          <div class="d-flex">
                            <div class="pr-2">
                              <div v-if="note.profile_image" class="mt-1 profile_image" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + note.user_id_external + '/' + note.profile_image + '/' + clientToken + ')' }"></div>
                              <div v-else-if="note.name" class="mt-1 profile_icon">
                                <userProfileAvatar :username="note.name" :size="20"/>
                              </div>
                            </div>
                            <div class="flex-grow-1">
                              <div class="text">
                                <span>{{note.note}}</span>
                              </div>
                              <div class="meta">
                                <span>{{note.created | moment("DD-MM-YYYY HH:mm")}}</span>
                              </div>
                            </div>
                          </div>                                                    
                        </div>
                      </div>
                      <div v-else>
                        <span>{{$t('service.No_notes_yet')}}</span>
                      </div>
                    </div>
                    <div class="w-100 d-flex your_note">
                      <div class="flex-grow-1">
                        <CTextarea @input="countdown()" rows="3" class="mb-0 flex-grow-1" type="text" v-model="newServiceQuestionNote.note" :placeholder="$t('service.Your_note')"/>
                      </div>
                      <CButton color="primary" class="ml-2 mr-0 p-0 d-flex align-items-center justify-content-center" @click="addServiceQuestionNote(activeServiceQuestion.service_question_id_external);">
                        <span class="material-icons text-center">add</span>
                      </CButton>
                    </div>
                  </div>                  
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow v-else>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <span>{{$t('service.Select_to_view_details')}}</span>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';
import FileUpload from '@/components/upload/FileUpload.vue';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

export default {
  name: 'Questions',
  components: {
    QuickEdit,
    Multiselect,
    FileUpload,
    loadingSpinner,
    noPermission,
    userProfileAvatar
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      apiBaseUrl: null,
      clientToken: null,
      userData: {},
      serviceQuestionsToShow: this.$t('service.All_questions'),
      serviceQuestions: [],
      activeServiceQuestion: {
        service_question_id_external: null
      },
      activeServiceQuestionMessages: [],
      activeServiceQuestionMessagesHidden: false,
      activeServiceQuestionMessagesLoading: false,
      activeServiceQuestionNotes: [],
      serviceQuestionStatuses: [],
      serviceQuestionAttachmentTypes: [],
      serviceQuestionsEmployees: [],
      serviceQuestionsAssignees: [],
      serviceQuestionFolders: [],
      serviceQuestionsFilter: {
        employees: [],
        folders: []
      },
      allHistoryMessagesLoaded: false,
      chatMessageInterval: null,
      chatReplyButtonDisabled: false,
      users: [],
      contentSearchTerm: '',
      senderSearchTerm: '',
      newServiceQuestionReply: {
        reply: "",
        attachments: []        
      },
      selectedAttachmentTypeTag: null,
      selectedAttachmentType: null,
      showServiceQuestionAttachmentTypes: false, 
      addNewServiceQuestionReplyAttachment: false,
      maxImageFilesize: 10485760,
      maxDocumentFilesize: 10485760,
      maxGridImages: 3,
      lightboxImages: [],
      lightboxActiveIndex: null,         
      newServiceQuestionNote: { note: "" },
      maxCount: 250,
      remainingCount: 250
    }
  },  
  methods: {
    checkGroupedMessage(index) {
      if(index > 0) {
        let message = this.activeServiceQuestionMessages[index];
        let previousMessage = this.activeServiceQuestionMessages[index-1];
        
        if((message.user_id_external === previousMessage.user_id_external) && (message.reply_date === previousMessage.reply_date)) {
          return true;
        } else {
          return false;
        }
      }
    },
    setActiveServiceQuestion(serviceQuestionIdExternal) {
      // Preset the external service question ID for the active service question
      this.activeServiceQuestion.service_question_id_external = serviceQuestionIdExternal;
      // Clear the interval if one is already running
      if(this.chatMessageInterval) clearInterval(this.chatMessageInterval);
      // Get the service question data
      this.getServiceQuestionData(serviceQuestionIdExternal, false, true);
      // Reset the new service question reply and note
      this.resetNewServiceQuestionReply();
      this.resetNewServiceQuestionNote();
      // Trigger the countdown function
      this.countdown();
    },  
    getServiceQuestions() {
      let questionsPath = null;

      if(this.serviceQuestionsToShow === this.$t('service.All_open_questions')) {
        questionsPath = 'questions/open';
      } else if(this.serviceQuestionsToShow === this.$t('service.My_questions')) {
        questionsPath = 'questions/my';
      } else if(this.serviceQuestionsToShow === this.$t('service.All_questions')) {
        questionsPath = 'questions';
      }

      axios.get(process.env.VUE_APP_API_URL + '/v1/service/' + questionsPath)
      .then(res => {
        this.serviceQuestions = res.data.data;
        // Filter the service questions
        this.filterServiceQuestions();
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getServiceQuestionData(serviceQuestionIdExternal, updateServiceQuestionData, hideServiceQuestionMessages) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/question/' + serviceQuestionIdExternal)
      .then(res => {
        this.activeServiceQuestion = res.data.data;
        // Get the most recent chat messages and notes
        if(this.activeServiceQuestion.service_question_id_external) {          
          this.getServiceQuestionMessages(this.activeServiceQuestion.service_question_id_external, hideServiceQuestionMessages);
          this.getServiceQuestionNotes(this.activeServiceQuestion.service_question_id_external);
        }
        // Get the service question index
        let serviceQuestionIndex = this.serviceQuestions.findIndex(x => x.service_question_id_external == serviceQuestionIdExternal);
        // Update the update_available value of the question        
        this.serviceQuestions[serviceQuestionIndex].update_available = false;

        if(updateServiceQuestionData) {          
          // Update the data of the question
          this.serviceQuestions[serviceQuestionIndex].last_message = this.activeServiceQuestion.last_message;
          this.serviceQuestions[serviceQuestionIndex].participants = this.activeServiceQuestion.participants;
          this.serviceQuestions[serviceQuestionIndex].assignee = this.activeServiceQuestion.assignee;
          this.serviceQuestions[serviceQuestionIndex].service_question_status = this.activeServiceQuestion.service_question_status;
          this.serviceQuestions[serviceQuestionIndex].service_question_status_tag = this.activeServiceQuestion.service_question_status_tag;                    
        }

        // Force update
        this.$forceUpdate();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getServiceQuestionMessages(serviceQuestionIdExternal, hideMessages) {
      // Update the activeServiceQuestionMessagesHidden value
      if(hideMessages) this.activeServiceQuestionMessagesHidden = true;
      // Set the start index
      let currentItemNumber = 0;
      // Update allHistoryMessagesLoaded value
      this.allHistoryMessagesLoaded = false;      
      // Get the service question messages
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/question/' + serviceQuestionIdExternal + '/messages/' + currentItemNumber)
      .then(res => {
        this.activeServiceQuestionMessages = res.data.data;       
        // Emit service_question_messages_read event
        this.$bus.$emit('service_question_messages_read');        
        // Wait 250ms before scrolling to the last message
        setTimeout(function(){
          let chatContainerHeight = this.$refs.itemMessageContainer.clientHeight;
          let chatScrollHeight = this.$refs.itemMessageContainer.scrollHeight;

          if((chatContainerHeight === chatScrollHeight)) {
            // Clear the interval if one is already running
            if(this.chatMessageInterval) clearInterval(this.chatMessageInterval);
            // Start the new interval        
            this.chatMessageInterval = setInterval(() => this.getNewServiceQuestionMessages(), 5000);
          } else {
            this.$refs.itemMessageContainer.scrollTo({top: chatScrollHeight})
          }
          // Show the loader if the first batch contains 10 messages already
          if(this.activeServiceQuestionMessages.length == 10) this.activeServiceQuestionMessagesLoading = true;          
          // Wait another 500ms before updating the activeServiceQuestionMessagesHidden value
          setTimeout(function(){  
            this.activeServiceQuestionMessagesHidden = false;
          }.bind(this), 500);
        }.bind(this), 250);
      })
      .catch(err => {
        console.error(err);
      });
    },
    updateServiceQuestionMessages() {
      let chatScrollPosition = this.$refs.itemMessageContainer.scrollTop + this.$refs.itemMessageContainer.clientHeight;
      let chatScrollHeight = this.$refs.itemMessageContainer.scrollHeight;      
      // Get history messages when chat is scrolled to top
      if(this.allHistoryMessagesLoaded === false && this.$refs.itemMessageContainer.scrollTop === 0) this.getHistoryServiceQuestionMessages();
      // Get new messages when chat is scrolled to bottom
      if((chatScrollPosition > (chatScrollHeight - 25)) && (chatScrollPosition < (chatScrollHeight + 25))) {
        // Clear the interval if one is already running
        if(this.chatMessageInterval) clearInterval(this.chatMessageInterval);
        // Start the new interval        
        this.chatMessageInterval = setInterval(() => this.getNewServiceQuestionMessages(), 5000);
      } else {
        clearInterval(this.chatMessageInterval);
      }
    },
    getHistoryServiceQuestionMessages() {      
      var initialHeight = this.$refs.itemMessageContainer.scrollHeight;
      // Get the current number of messages
      let currentItemNumber = this.activeServiceQuestionMessages.length;
      // Get the chat messages
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/question/' + this.activeServiceQuestion.service_question_id_external + '/messages/' + currentItemNumber)
      .then(res => {
        let additionalMessages = res.data.data;
        // Check if there are additional chat messages available
        if(additionalMessages.length > 0) {
          // If so, add them in front of the array
          this.activeServiceQuestionMessages.unshift(...additionalMessages)
          // Stick to the current scroll position
          this.$nextTick(() => {            
            this.$refs.itemMessageContainer.scrollTo({top: (this.$refs.itemMessageContainer.scrollHeight - initialHeight)})
          })
        } else {
          // Update allHistoryMessagesLoaded value
          this.allHistoryMessagesLoaded = true;
          // Stop the loader
          this.activeServiceQuestionMessagesLoading = false;          
        } 
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getNewServiceQuestionMessages() {
      // Get the new chat messages
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/question/' + this.activeServiceQuestion.service_question_id_external + '/messages/new')
      .then(res => {
        let additionalMessages = res.data.data;
        // Check if there are additional chat messages available
        if(additionalMessages.length > 0) {
          // If so, add them in front of the array
          this.activeServiceQuestionMessages.push(...additionalMessages)
          // Stick to the current scroll position
          this.$nextTick(() => {            
            this.$refs.itemMessageContainer.scrollTo({top: (this.$refs.itemMessageContainer.scrollHeight)})
          })
        }
      })
      .catch(err => {
        console.error(err); 
      });    
    },
    getServiceQuestionNotes(serviceQuestionIdExternal) {
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/question/' + serviceQuestionIdExternal + '/notes')
      .then(res => {
        this.activeServiceQuestionNotes = res.data.data;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getServiceQuestionFolders() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/service/questions/folders')
      .then(res => {
        this.serviceQuestionFolders = res.data.data;
        // Set the folder filter if empty
        if(this.serviceQuestionsFilter.folders.length === 0) {
          this.serviceQuestionsFilter.folders = res.data.data;
          // Update the folder filter
          this.updateFolderFilter();
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    submitServiceQuestionReply(serviceQuestionIdExternal, resolveServiceQuestion) {
      // Set the params      
      let params = {};
      params.replyData = this.newServiceQuestionReply;
      if(params.replyData.reply === "") params.replyData.reply = null;
      params.replyData.replied_from = 'dashboard';
      
      let reply = params.replyData.reply;
      let attachments = params.replyData.attachments;

      if(reply || attachments.length > 0) {
        // Update the chatReplyButtonDisabled value
        this.chatReplyButtonDisabled = true;
        // Post the reply              
        axios.post(process.env.VUE_APP_API_URL + '/v1/service/question/' + serviceQuestionIdExternal + '/reply', params)
        .then(res => {
          let serviceQuestionIdExternal = res.data.data.service_question_id_external;
          let threadMessageIdExternal =  res.data.data.thread_message_id_external;
          let totalAttachments = this.newServiceQuestionReply.attachments.length;
          let attachmentsUploaded = 0;

          if(totalAttachments > 0) {
            // Loop through the attachments
            for(var a = 0; a < this.newServiceQuestionReply.attachments.length; a++) {
              let attachmentsIndex = a;            
              // Create new formdata         
              const formData = new FormData();              
              // Append the image to the formdata
              if(this.newServiceQuestionReply.attachments[attachmentsIndex].attachment_type_tag === 'image') {
                formData.append("file", this.newServiceQuestionReply.attachments[attachmentsIndex].image);
              } else if(this.newServiceQuestionReply.attachments[attachmentsIndex].attachment_type_tag === 'file') {
                formData.append("file", this.newServiceQuestionReply.attachments[attachmentsIndex].file);
              }
              // Append necessary data to the formdata
              formData.append('attachment_event', 'new_service_question_message');
              formData.append('attachment_event_external_id', threadMessageIdExternal);
              formData.append('attachment_type_tag', this.newServiceQuestionReply.attachments[attachmentsIndex].attachment_type_tag);
              formData.append('attachment_directory', serviceQuestionIdExternal);
                              
              axios.post(process.env.VUE_APP_API_URL + '/v1/service/question/attachment', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
              })
              .then(res => {
                // Update the attachmentsUploaded value
                attachmentsUploaded = attachmentsUploaded + 1;
                // Check if all attachments have been uploaded
                if(attachmentsUploaded === totalAttachments) {
                  // Update the addNewServiceQuestionReplyAttachment value
                  this.addNewServiceQuestionReplyAttachment = false;
                  // Reset the reply data
                  this.resetNewServiceQuestionReply();
                  // Resolve the service question if necessary
                  if(resolveServiceQuestion) this.setServiceQuestionStatus(serviceQuestionIdExternal, 'done');
                  // Get service question data
                  this.getServiceQuestionData(serviceQuestionIdExternal, true, false);
                  // Reset the chatReplyButtonDisabled value
                  this.chatReplyButtonDisabled = false;                  
                  // Trigger countdown function
                  this.countdown();                    
                }
              })
              .catch(err => {
                console.error(err); 
              });            
            }        
          } else {
            // Reset the reply data
            this.resetNewServiceQuestionReply();
            // Resolve the service question if necessary
            if(resolveServiceQuestion) this.setServiceQuestionStatus(serviceQuestionIdExternal, 'done');
            // Get service question data
            this.getServiceQuestionData(serviceQuestionIdExternal, true, false);
            // Reset the chatReplyButtonDisabled value
            this.chatReplyButtonDisabled = false;            
            // Trigger countdown function
            this.countdown();              
          }
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        this.$buefy.toast.open({ message: this.$t('service.Empty_reply'), type: 'is-danger', duration: 2000 });
      }     
    },
    visibilityChanged(isVisible, entry, threadMessageIdExternal){
      if(this.activeServiceQuestion && isVisible === true) {
        axios.post('v1/service/question/' + this.activeServiceQuestion.service_question_id_external + '/read/message/' + threadMessageIdExternal)
        .catch(err => {
          console.error(err); 
        });
      }
    },    
    addServiceQuestionNote(serviceQuestionIdExternal) {
      // Set the params      
      let params = {};
      params.noteData = this.newServiceQuestionNote;
      
      let note = params.noteData.note;

      if(note) {        
        axios.post(process.env.VUE_APP_API_URL + '/v1/service/question/' + serviceQuestionIdExternal + '/note', params)
        .then(res => {
          // Update the service question notes
          this.getServiceQuestionNotes(serviceQuestionIdExternal);
          // Reset the note data
          this.resetNewServiceQuestionNote();
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        this.$buefy.toast.open({ message: this.$t('service.Empty_reply'), type: 'is-danger', duration: 2000 });
      }     
    },       
    resetActiveServiceQuestion() {
      this.activeServiceQuestions = {
        service_question_id_external: null
      };
    },
    resetNewServiceQuestionReply() {
      this.newServiceQuestionReply = {
        reply: "",
        attachments: []
      }
    },
    resetNewServiceQuestionNote() {
      this.newServiceQuestionNote = {
        note: ""
      };
    },
    resetServiceQuestionAttachment() {
      this.selectedAttachmentTypeTag = null;
      this.selectedAttachmentType = null;
      // Reset the attachments array of the service question reply
      this.newServiceQuestionReply.attachments = [];
      // Update the addNewServiceQuestionReplyAttachment value
      this.addNewServiceQuestionReplyAttachment = false;
      // Update the showServiceQuestionAttachmentTypes value
      this.showServiceQuestionAttachmentTypes = false;
    },       
    setServiceQuestionAssignee(serviceQuestionIdExternal, assigneeUserIdExternal) {
      let params = {};
      params.assignee_user_id_external = assigneeUserIdExternal;

      axios.put(process.env.VUE_APP_API_URL + '/v1/service/question/' + serviceQuestionIdExternal + '/assignee', params)
      .then(res => {
        // Get service question data
        this.getServiceQuestionData(serviceQuestionIdExternal, true, false);        
        // Emit service_question_assigned event
        this.$bus.$emit('service_question_assigned'); 
      })
      .catch(err => {
        console.error(err); 
      });
    },
    setServiceQuestionFolder(serviceQuestionIdExternal, folderIdExternal) {
      let params = {};
      params.service_question_folder_id_external = folderIdExternal;

      axios.put(process.env.VUE_APP_API_URL + '/v1/service/question/' + serviceQuestionIdExternal + '/folder', params)
      .then(res => {
        // Get service question data
        this.getServiceQuestionData(serviceQuestionIdExternal, true, false);
        // Refresh the service questions
        this.getServiceQuestions();
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    setServiceQuestionStatus(serviceQuestionIdExternal, serviceQuestionStatusTag) {
      let params = {};
      params.service_question_status_tag = serviceQuestionStatusTag;

      axios.put(process.env.VUE_APP_API_URL + '/v1/service/question/' + serviceQuestionIdExternal + '/status', params)
      .then(res => {
        // Get service question data
        this.getServiceQuestionData(serviceQuestionIdExternal, true, false);        
      })
      .catch(err => {
        console.error(err); 
      });
    },        
    getServiceQuestionStatusColor(statusTag) {
      if(statusTag === 'open') {
        return 'var(--DashboardDefaultGreen)';
      } else if(statusTag === 'in_progress') {
        return 'var(--DashboardDefaultOrange)';
      } else if(statusTag === 'done') {
        return 'var(--DashboardLightGreen)';
      }
    },    
    getServiceQuestionAttachmentTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/threads/attachments/types/dashboard/list')
      .then(res => {
        this.serviceQuestionAttachmentTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    setServiceQuestionAttachmentType(type) {
      // Set the attachment type data
      this.selectedAttachmentTypeTag = type.attachment_type_tag;
      this.selectedAttachmentType = type.attachment_type;
          
      // Auto trigger the file pickers after 250ms
      if(this.selectedAttachmentTypeTag === 'image') {
        setTimeout(function(){
          this.pickImageToUpload();      
        }.bind(this), 250);
      } else if(this.selectedAttachmentTypeTag === 'file') {
        setTimeout(function(){
          this.pickFileToUpload();      
        }.bind(this), 250);
      }
    },
    getUserData() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/me')
      .then(res => {
        this.userData = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    filterServiceQuestions() {
      if(this.serviceQuestionsFilter.employees.length > 0 || this.serviceQuestionsFilter.folders.length > 0) {
        let selectedEmployees = [];
        let selectedFolders = [];

        // Gather external ID's of selected employees
        for(var e = 0; e < this.serviceQuestionsFilter.employees.length; e++) {
          selectedEmployees.push(this.serviceQuestionsFilter.employees[e].user_id_external);
        }
        
        // Gather external ID's of selected folders
        for(var f = 0; f < this.serviceQuestionsFilter.folders.length; f++) {
          selectedFolders.push(this.serviceQuestionsFilter.folders[f].service_question_folder_id_external);
        }
        
        var vm = this;
                
        this.serviceQuestions = this.serviceQuestions.filter(function(item) {
          if(vm.serviceQuestionsFilter.employees.length > 0 && vm.serviceQuestionsFilter.folders.length > 0) {
            return selectedEmployees.includes(item.employee_user_id_external) && selectedFolders.includes(item.service_question_folder_id_external);
          } else if(vm.serviceQuestionsFilter.employees.length > 0) {
            return selectedEmployees.includes(item.employee_user_id_external);  
          } else if(vm.serviceQuestionsFilter.folders.length > 0) {
            return selectedFolders.includes(item.service_question_folder_id_external);
          }          
        })
      }    
    },    
    updateEmployeeFilter() {
      localStorage.setItem('serviceQuestionEmployeeFilter', JSON.stringify(this.serviceQuestionsFilter.employees));
      // Gather the service questions
      this.getServiceQuestions();
    },
    updateFolderFilter() {
      localStorage.setItem('serviceQuestionFolderFilter', JSON.stringify(this.serviceQuestionsFilter.folders));
      // Gather the service questions
      this.getServiceQuestions();
    },    
    onImagesChange(e) {
      // Get the files
      let files = e.target.files;    
      // Loop through the files
      for(var i = 0; i < files.length; i++) {
        // Check if the filesize is allowed
        if(files[i].size <= this.maxImageFilesize) {          
          // Create a newAttachment object
          let newAttachment = {
            attachment_type_tag: this.selectedAttachmentTypeTag,
            attachment_type: this.selectedAttachmentType,
            image: files[i],
            image_url: URL.createObjectURL(files[i]),
            image_name: files[i].name    
          };
          // And add the newAttachment to the attachments array
          this.newServiceQuestionReply.attachments.push(newAttachment);
        } else {
          // Notify the user if the filesize exceed the maximum filesize
          this.$buefy.toast.open({ message: files[i].name + ' (' + this.formatFileSize(files[i].size) + ') ' + this.$t('upload.exceeds_maximum_filesize_of') + ' ' + this.formatFileSize(this.maxImageFilesize) + '.', type: 'is-danger', duration: 2000 });
        }
      }      
      // Update the showServiceQuestionAttachmentTypes value
      this.showServiceQuestionAttachmentTypes = false;
      // Force update
      this.$forceUpdate();
    },
    onFilesChange(e) {
      // Get the files
      let files = e.target.files;      
      // Loop through the files
      for(var i = 0; i < files.length; i++) {
        // Check if the filesize is allowed
        if(files[i].size <= this.maxImageFilesize) {          
          // Create a newAttachment object
          let newAttachment = {
            attachment_type_tag: this.selectedAttachmentTypeTag,
            attachment_type: this.selectedAttachmentType,
            file: files[i],
            file_url: URL.createObjectURL(files[i]),
            file_name: files[i].name
          };
          // And add the newAttachment to the attachments array
          this.newServiceQuestionReply.attachments.push(newAttachment);
        } else {
          // Notify the user if the filesize exceed the maximum filesize    
          this.$buefy.toast.open({ message: files[i].name + ' (' + this.formatFileSize(files[i].size) + ') ' + this.$t('upload.exceeds_maximum_filesize_of') + ' ' + this.formatFileSize(this.maxImageFilesize) + '.', type: 'is-danger', duration: 2000 });
        }
      }
      // Update the showServiceQuestionAttachmentTypes value
      this.showServiceQuestionAttachmentTypes = false;
      // Force update
      this.$forceUpdate();          
    },    
    pickImageToUpload() {
      document.getElementById("imageUpload").click();
    },
    pickFileToUpload() {
      document.getElementById("fileUpload").click();
    },
    formatFileSize(bytes) {
      const sufixes = ['B', 'kB', 'MB', 'GB', 'TB'];
      const i = Math.floor(Math.log(bytes) / Math.log(1024));
      return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`;
    },
    openImageLightbox(images, index) {
      // Create a new imageUrls arrau
      let imageUrls = [];
      // Add the image URL's to the imageURLs array
      for(var i = 0; i < images.length; i++) imageUrls.push(images[i].url);      
      // Set the image URl's
      this.lightboxImages = imageUrls;
      // Set the active index
      this.lightboxActiveIndex = index;
    },
    openFileAttachment(fileUrl) {
      window.open(fileUrl);
    },
    downloadFileAttachment(fileName, fileUrl) {
      // Create a new XHR request
      var downloadRequest = new XMLHttpRequest();
      downloadRequest.open('GET', fileUrl, true);
      downloadRequest.responseType = 'blob';

      downloadRequest.onload = function(e) {
        if(this.status == 200) {
          var downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(this.response);
          downloadLink.download = fileName;
          downloadLink.click();
        }
      };
      downloadRequest.send();
    },
    asyncFindEmployee(searchTerm) {
      this.serviceQuestionsEmployees = [];
      
      if(searchTerm.length >= 3) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.serviceQuestionsEmployees = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindAssignee(searchTerm) {
      this.serviceQuestionsAssignees = [];
      
      if(searchTerm.length >= 3) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.serviceQuestionsAssignees = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },    
    countdown() {
      this.remainingCount = this.maxCount - this.newServiceQuestionReply.reply.length;
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }        
  },
  computed: {
    searchedServiceQuestions() {
      return this.serviceQuestions.filter((serviceQuestion) => {
        return (serviceQuestion.question && serviceQuestion.question.toLowerCase().includes(this.contentSearchTerm.toLowerCase())) || 
               (serviceQuestion.participant.name.toLowerCase().includes(this.contentSearchTerm.toLowerCase())) ||
               (serviceQuestion.participants.some((subElement) => subElement.name.toLowerCase().includes(this.contentSearchTerm.toLowerCase())))
      });
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
    
    this.getPlatformPermissions();
    this.getServiceQuestions();
    this.getServiceQuestionFolders();
    this.resetActiveServiceQuestion();
    this.resetNewServiceQuestionReply();
    this.resetNewServiceQuestionNote();
    this.getServiceQuestionAttachmentTypes();
    this.getUserData();

    // If available, set the employee filter according to localStorage data
    if(localStorage.getItem('serviceQuestionEmployeeFilter') !== null) {
      this.serviceQuestionsFilter.employees = JSON.parse(localStorage.getItem('serviceQuestionEmployeeFilter'));
    }

    // If available, set the folders filter according to localStorage data
    if(localStorage.getItem('serviceQuestionFolderFilter') !== null) {
      this.serviceQuestionsFilter.folders = JSON.parse(localStorage.getItem('serviceQuestionFolderFilter'));
    }
    
    // If available, set the active Service Question according to localStorage data
    if(localStorage.getItem('serviceQuestionToActivate') !== null) {
      this.setActiveServiceQuestion(localStorage.getItem('serviceQuestionToActivate'));
      // Remove the serviceQuestionToActivate from localStorage
      localStorage.removeItem('serviceQuestionToActivate');
    }    
  },
  beforeDestroy() {
    clearInterval(this.chatMessageInterval);
  }
}
</script>
